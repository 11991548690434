var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.form
      ? _c(
          "form",
          {
            class: { hideDisclaimer: _vm.hideDisclaimer },
            attrs: { action: "" },
          },
          [
            _c("schema-form-field-list", {
              key: _vm.renderKey,
              attrs: {
                fields: _vm.form.children,
                "contextual-jurisdiction": _vm.jurisdiction,
                disabled: _vm.disabled,
                "show-company-mailing-address-option":
                  _vm.showCompanyMailingAddressOption,
              },
              on: {
                "suggestion-toggled": function ($event) {
                  return _vm.$emit("suggestion-toggled", $event)
                },
                input: _vm.valuesUpdated,
                "ra-signup": function ($event) {
                  return _vm.$emit("ra-signup")
                },
                "show-contact-modal": _vm.showContactModal,
              },
            }),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }