<template>
  <div>
    <form v-if="form" action="" :class="{ hideDisclaimer }">
      <schema-form-field-list
        :key="renderKey"
        :fields="form.children"
        :contextual-jurisdiction="jurisdiction"
        :disabled="disabled"
        :show-company-mailing-address-option="showCompanyMailingAddressOption"
        @suggestion-toggled="$emit('suggestion-toggled', $event)"
        @input="valuesUpdated"
        @ra-signup="$emit('ra-signup')"
        @show-contact-modal="showContactModal"
      />
    </form>
  </div>
</template>

<script>
  import { SchemaForm } from './form'
  import SchemaFormFieldList from './fields/SchemaFormFieldList'
  import { mapGetters } from 'vuex'

  export default {
    name: 'SchemaForm',

    components: {
      SchemaFormFieldList,
    },
    props: {
      fields:  [Object, Array],
      value: Object,
      suggestionFields: [Object, Array],
      contextualJurisdiction: [Object, String],
      disabled: {
        type: Boolean,
        default: false,
      },
      hideDisclaimer: {
        type: Boolean,
        default: false,
      },
      showCompanyMailingAddressOption: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        form: null,
        renderKey: 0,
        jurisdiction: undefined,
      }
    },
    computed: {
      ...mapGetters('jurisdictions', ['findByName']),
    },

    mounted() {
      if (!this.fields) return
      this.setJurisdiction()
      this.initSchemaForm()
    },
    beforeDestroy() {
      if (this.form) {
        this.form.onChildAddedOrRemoved = null
      }
    },
    methods: {
      setJurisdiction() {
        this.jurisdiction = typeof(this.contextualJurisdiction) === 'object' ?
          this.contextualJurisdiction : this.findByName(this.contextualJurisdiction)
      },
      initSchemaForm() {
        this.appendSuggestionValuesToFields()
        this.form = new SchemaForm(this.fields, this.value)
        this.form.onChildAddedOrRemoved = () => {
          this.$emit('input', this.form.values)
        }
      },
      valuesUpdated(ev) {
        this.form.set(ev.path, ev.value)

        this.$emit('input', this.form.values)
      },
      showContactModal() {
        this.$emit('show-contact-modal')
      },
      forceRerender() {
        ++this.renderKey
      },
      appendSuggestionValuesToFields() {
        if (this.suggestionFields) {
          this.fields.forEach(f => {
            if (this.suggestionFields[f.name]) {
              // We allow overriding the use hint from this suggestion meta context. We do it on the
              // backend, but we kinda were re-adding the useHint here so, also check here
              // Would be good to clean this up and just do it on the backend, but im not sure
              // of the ramifications of changing this logic from what it was
              f.meta?.suggestion?.context?.skip_hint ? f.useHint = false :  f.useHint = true
            } else {
              f.useHint ? f.useHint = false : f
            }
          })
        }
        this.forceRerender()
      },
    },
  }
</script>

<style lang="scss" scoped>
  .hideDisclaimer ::v-deep #duplicate-add-button-breaker-do-not-filter-just-hide-if-needed {
    visibility: hidden;
    display: none;
  }
</style>
